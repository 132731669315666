import { LoadingIndicator } from "components/atoms/LoadingIndicator";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import React, { FC, useContext, useEffect } from "react";
import { Project } from "~/models/project";
import Layout from "mypage/src/components/layout";
import { MetaTag } from "mypage/src/components/modules/metaTag";
import LoginPresenter from "mypage/src/components/templates/login/loginPresenter";
import { pagesPath } from "mypage/src/lib/$path";
import { AuthContext } from "mypage/src/lib/auth/AuthProvider";

interface Props {
  project: Project;
}

const TemplateLogin: FC<Props> = ({ project }) => {
  const {
    firebaseUser,
    isFirebaseIdTokenChanging,
    isFirebaseLoading,
    loginInWithGoogle,
  } = useContext(AuthContext);
  const router = useRouter();
  const { t } = useTranslation();

  useEffect(() => {
    if (!firebaseUser) {
      return;
    }

    const callbackUrl = router.query.callback_url;
    if (typeof callbackUrl === "string") {
      router.push(callbackUrl);
    } else {
      router.push(pagesPath.home.$url());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebaseUser]);

  // clear history when login
  useEffect(() => {
    localStorage.removeItem("mypage::history");
  }, []);

  if (isFirebaseLoading || isFirebaseIdTokenChanging || firebaseUser) {
    return <LoadingIndicator />;
  }

  return (
    <Layout isShowSideMenu={false}>
      <>
        <MetaTag
          isNoIndexPage={true}
          project={project}
          title={t("login:meta.title")}
        />
        <LoginPresenter loginInWithGoogle={loginInWithGoogle} t={t} />
      </>
    </Layout>
  );
};

export default TemplateLogin;
