import { staticPath } from "faqSrc/lib/$path";
import React, { VFC } from "react";
import styled, { css } from "styled-components";
import Image from "components/atoms/Image";
import ShortTextSupport from "~/components/atoms/ShortTextSupport";
import {
  breakPoint,
  colorBlack,
  colorImportant,
  colorPriceRankPrimary,
  colorPrimary,
} from "~/lib/constants";

type ButtonVariant =
  | "back"
  | "danger"
  | "important"
  | "info"
  | "lightBack"
  | "outline"
  | "primary"
  | "primaryBack"
  | "secondary";
type ButtonSize = "tiny" | "small" | "medium" | "large";

interface ButtonProps {
  disabled?: boolean;
  icon?:
    | typeof staticPath["img"][keyof typeof staticPath["img"]]
    | typeof staticPath["img"]["review_step"][keyof typeof staticPath["img"]["review_step"]];
  iconHeight?: number;
  iconWidth?: number;
  inheritStyle?: boolean;
  hasUnderline?: boolean;
  maxWidth?: number;
  minWidth?: number;
  onClick?: () => void;
  shortText?: string;
  size?: ButtonSize;
  text: string;
  variant?: ButtonVariant;
  withRightArrow?: boolean;
}

const heightMap: { [key in ButtonSize]: number } = {
  large: 52,
  medium: 40,
  small: 36,
  tiny: 32,
};

const backgroundColorMap: { [key in ButtonVariant]: string } = {
  back: colorBlack[50],
  danger: colorImportant[500],
  important: colorImportant[10],
  info: colorPrimary[50],
  lightBack: colorBlack[30],
  outline: "#fff",
  primary: colorPrimary[500],
  primaryBack: colorPrimary[50],
  secondary: colorPriceRankPrimary[500],
};

const colorMap: { [key in ButtonVariant]: string } = {
  back: colorBlack[400],
  danger: "#fff",
  important: colorImportant[500],
  info: colorPrimary[600],
  lightBack: colorBlack[300],
  outline: colorPrimary[500],
  primary: "#fff",
  primaryBack: colorPrimary[600],
  secondary: "#fff",
};

const borderColorMap: { [key in ButtonVariant]: string } = {
  back: colorBlack[50],
  danger: colorImportant[500],
  important: colorImportant[500],
  info: colorPrimary[50],
  lightBack: colorBlack[30],
  outline: colorPrimary[500],
  primary: colorPrimary[500],
  primaryBack: colorPrimary[50],
  secondary: colorPriceRankPrimary[500],
};

const rightArrowMap: { [key in ButtonVariant]: string } = {
  back: staticPath.img.icon_arrow_right_gray_svg,
  danger: staticPath.img.white_chevron_right_svg,
  important: staticPath.img.icon_arrow_right_red_svg,
  info: staticPath.img.green_arrow_right_light_svg,
  lightBack: staticPath.img.icon_arrow_right_gray_svg,
  outline: staticPath.img.green_arrow_right_svg,
  primary: staticPath.img.white_chevron_right_svg,
  primaryBack: staticPath.img.green_arrow_right_light_svg,
  secondary: staticPath.img.white_chevron_right_svg,
};

const Button: VFC<ButtonProps> = ({
  disabled,
  icon,
  iconHeight = 18,
  iconWidth = 18,
  inheritStyle,
  hasUnderline,
  maxWidth,
  minWidth,
  onClick,
  shortText,
  size = "large",
  text,
  variant = "primary",
  withRightArrow,
}) => {
  return (
    <Container
      aria-disabled={disabled}
      dataInheritStyle={inheritStyle}
      dataHasUnderline={hasUnderline}
      dataMaxWidth={maxWidth}
      dataMinWidth={minWidth}
      disabled={disabled}
      onClick={disabled ? undefined : onClick}
      size={size}
      variant={variant}
      withRightArrow={withRightArrow}
    >
      {typeof icon === "string" && (
        <Image
          src={icon}
          width={iconWidth}
          height={iconHeight}
          layout="fixed"
          ampLayout="fixed"
          alt={text}
        />
      )}
      {shortText ? (
        <ShortTextSupport fullText={text} shortText={shortText} />
      ) : (
        text
      )}
      {withRightArrow && (
        <ArrowContainer>
          <Image
            src={rightArrowMap[variant]}
            alt="icon more"
            height={12}
            width={8}
          />
        </ArrowContainer>
      )}
    </Container>
  );
};

const Container = styled.div<{
  dataInheritStyle?: boolean;
  dataHasUnderline?: boolean;
  dataMaxWidth?: number;
  dataMinWidth?: number;
  disabled?: boolean;
  size: ButtonSize;
  variant: ButtonVariant;
  withRightArrow?: boolean;
}>`
  align-items: center;
  background: ${(props) => backgroundColorMap[props.variant]};
  border-radius: 5px;
  border: 1px solid ${(props) => borderColorMap[props.variant]};
  color: ${(props) => colorMap[props.variant]};
  cursor: pointer;
  display: grid;
  grid-auto-flow: column;
  gap: 12px;
  height: ${(props) => heightMap[props.size]}px;
  justify-content: center;
  max-width: ${(props) =>
    props.dataMaxWidth ? `${props.dataMaxWidth}px` : "auto"};
  min-width: ${(props) =>
    props.dataMinWidth ? `${props.dataMinWidth}px` : "auto"};
  padding: ${(props) => (props.size === "large" ? "0 16px" : "0 12px")};
  text-align: center;
  text-decoration: ${(props) =>
    props.dataHasUnderline ? "underline" : "unset"};

  ${(props) => {
    if (props.dataInheritStyle) {
      return css`
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
      `;
    } else {
      return css`
        font-size: ${props.withRightArrow ? "16px" : "18px"};
        font-weight: 700;
        line-height: 30px;
      `;
    }
  }}

  ${(props) =>
    props.size === "small" &&
    css`
      white-space: nowrap;
    `}
  
  &:hover {
    opacity: 0.8;
  }

  ${({ disabled }) =>
    disabled &&
    `
    background: ${colorBlack[30]};
    border-color: ${colorBlack[30]};
    color: ${colorBlack[300]};
    cursor: not-allowed;
    &:hover {
      opacity: 1;
    }
  `}

  @media only screen and (max-width: ${breakPoint["sm"]}) {
    gap: 8px;
  }
`;

const ArrowContainer = styled.span`
  @media only screen and (max-width: ${breakPoint["xs"]}) {
    display: none;
  }
`;

export default Button;
