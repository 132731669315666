import React, { FC } from "react";
import styled from "styled-components";
import { breakPoint } from "lib/constants";

interface Props {
  fullText: string;
  shortText: string;
}

const ShortTextSupport: FC<Props> = ({ fullText, shortText }) => {
  return (
    <>
      <FullText>{fullText}</FullText>
      <ShortText>{shortText}</ShortText>
    </>
  );
};

const FullText = styled.div`
  white-space: nowrap;
  display: none;
  @media only screen and (min-width: ${parseInt(breakPoint["sm"], 10) +
    1 +
    "px"}) {
    display: block;
  }
`;

const ShortText = styled.div`
  white-space: nowrap;
  display: none;
  @media only screen and (max-width: ${breakPoint["sm"]}) {
    display: block;
  }
`;

export default ShortTextSupport;
