import React, { VFC } from "react";
import styled, { keyframes } from "styled-components";
import { colorPrimary } from "lib/constants";

interface Props {
  margin?: number;
  size?: number;
}

export const CircularProgress: VFC<Props> = ({ margin, size }) => (
  <Container>
    <CircularProgressContainer margin={margin} size={size}>
      <svg viewBox="22 22 44 44">
        <circle
          className="circleIndeterminate"
          cx="44"
          cy="44"
          fill="none"
          r="20.2"
          strokeWidth="3.6"
        ></circle>
      </svg>
    </CircularProgressContainer>
  </Container>
);

const keyFrameCircularRotate = keyframes`
  100% {
    transform: rotate(360deg)
  }
`;

const keyFrameCircularDash = keyframes`
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px;
  }
`;

const CircularProgressContainer = styled.div<Props>`
  animation: ${keyFrameCircularRotate} 1.4s linear infinite;
  color: ${colorPrimary[500]};
  display: inline-block;
  height: ${({ size }) => (size !== undefined ? size : 40)}px;
  width: ${({ size }) => (size !== undefined ? size : 40)}px;
  margin: ${({ margin }) => (margin !== undefined ? margin : 40)}px;

  .circleIndeterminate {
    animation: ${keyFrameCircularDash} 1.4s ease-in-out infinite;
    stroke: currentColor;
    stroke-dasharray: 80px, 200px;
    stroke-dashoffset: 0;
  }
`;

const Container = styled.div`
  text-align: center;
`;
