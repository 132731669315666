import React, { FC } from "react";
import styled from "styled-components";
import { CircularProgress } from "~/components/atoms/CircularProgress";

export const LoadingIndicator: FC = () => (
  <LoadingContainer>
    <CircularProgress />
  </LoadingContainer>
);

const LoadingContainer = styled.div`
  text-align: center;
  height: 100vh;
  display: grid;
  align-items: center;
  justify-content: center;
`;
