import { Translate } from "next-translate";
import React, { FC } from "react";
import styled from "styled-components";
import { centerTitle } from "styles/CommonStyles";
import Button from "~/components/atoms/button";

interface Props {
  loginInWithGoogle: () => void;
  t: Translate;
}

const LoginPresenter: FC<Props> = ({ loginInWithGoogle, t }) => (
  <div className="content-wrap">
    <Title>{t("login:title.normal")}</Title>
    <LoginButton onClick={loginInWithGoogle}>
      <Button
        text={t("login:button.google")}
        icon="/img/icon-google.svg"
        inheritStyle={true}
      />
    </LoginButton>
  </div>
);

const Title = styled.div`
  ${centerTitle};
  margin: 40px 0;
`;

const LoginButton = styled.div`
  max-width: 382px;
  margin: 0 auto;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;

  @media only screen and (max-width: 386px) {
    font-size: 16px;
  }

  @media only screen and (max-width: 340px) {
    font-size: 14px;
  }
`;

export default LoginPresenter;
