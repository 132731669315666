import { GetStaticProps, InferGetStaticPropsType } from "next";
import loadNamespaces from "next-translate/loadNamespaces";
import React, { FC } from "react";
import { retrieveProjectById } from "~/iterators/firestore/project/getters/retrieveProjectById";
import { getCurrentProjectId } from "~/lib/utils/urlHelper";
import TemplateLogin from "mypage/src/components/templates/login";
import { pagesPath } from "mypage/src/lib/$path";

export type OptionalQuery = {
  callback_url: string;
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const projectId = getCurrentProjectId();
  const project = await retrieveProjectById({ projectId });

  return {
    props: {
      project,
      ...(await loadNamespaces({
        locale,
        pathname: pagesPath.login.$url().pathname,
      })),
    },
  };
};

type Props = InferGetStaticPropsType<typeof getStaticProps>;
const Login: FC<Props> = ({ project }) => <TemplateLogin project={project} />;

export default Login;
